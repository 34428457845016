import { Grid, Typography, TextField, Stack, Box, Paper } from '@mui/material';
import { WaitButton } from 'components';
import { Form, notEmpty, emptyOr, mustBeAuNumber, Select } from 'components/form';
import { useAsyncState } from 'app/hooks';
import { useAppDispatch, updateProfile } from 'app/store';

import type { state } from 'app';

type UserData = Pick<
  state.User,
  | 'userFirstname'
  | 'userSurname'
  | 'phoneNumber'
  // 'userPhone' | 'userPhoneAlt' |
  | 'childName'
  | 'childPronouns'
>;

const validators = {
  userFirstname: notEmpty(),
  userSurname: notEmpty(),
  phoneNumber: mustBeAuNumber(),
  // userPhone: mustBeAuNumber(),
  // userPhoneAlt: emptyOr(mustBeAuNumber()),
  childName: notEmpty(),
  childPronouns: notEmpty('Please select one'),
};

const preferredPronounsOptions = [
  {
    label: 'He/Him/His',
    value: 'male',
  },
  {
    label: 'She/Her/Hers',
    value: 'female',
  },
  {
    label: 'They/Them/Their',
    value: 'non-binary',
  },
];

export function UserProfileForm({ user, onClose }: { user: state.User; onClose: () => void }) {
  const dispatch = useAppDispatch();

  const [updateState, success, failure, waiting] = useAsyncState();

  const handleSubmit = (data: UserData) => {
    const user = Object.entries(data).reduce<Partial<state.User>>((user, [key, value]) => {
      return { ...user, [key]: value === undefined ? null : value };
    }, {});
    dispatch(updateProfile(user))
      .unwrap()
      .then(() => {
        success();
        onClose();
      })
      .catch(failure);
    waiting();
  };

  return (
    <Paper sx={{ p: 2, my: 2 }}>
      <Typography variant="h6" color="secondary">
        Update user profile
      </Typography>
      <Typography variant="subtitle1" color="textSecondary">
        You can update your profile details.
      </Typography>
      <Form<UserData> onSubmit={handleSubmit} validators={validators} initialData={user}>
        {(state, updateField) => (
          <Box mt={2}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="userFirstname"
                  variant="filled"
                  label="Firstname"
                  value={state.data.userFirstname}
                  onChange={(e) => updateField('userFirstname', e.target.value)}
                  error={Boolean(state.errors.userFirstname)}
                  helperText={state.errors.userFirstname}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="userSurname"
                  variant="filled"
                  label="Surname"
                  value={state.data.userSurname}
                  onChange={(e) => updateField('userSurname', e.target.value)}
                  error={Boolean(state.errors.userSurname)}
                  helperText={state.errors.userSurname}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="phoneNumber"
                  variant="filled"
                  label="Prefered phone"
                  value={state.data.phoneNumber}
                  onChange={(e) => updateField('phoneNumber', e.target.value)}
                  error={Boolean(state.errors.phoneNumber)}
                  helperText={state.errors.phoneNumber || 'A valid Australia mobile number'}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="childName"
                  variant="filled"
                  label="Child name"
                  value={state.data.childName}
                  onChange={(e) => updateField('childName', e.target.value)}
                  error={Boolean(state.errors.childName)}
                  helperText={state.errors.childName}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Select
                  name="childPronouns"
                  variant="filled"
                  label="What are your child's preferred pronouns?"
                  options={preferredPronounsOptions}
                  value={state.data.childPronouns}
                  onChange={(e) => updateField('childPronouns', e.target.value)}
                  error={Boolean(state.errors.childPronouns)}
                  helperText={state.errors.childPronouns}
                  fullWidth
                  required
                />
              </Grid>
            </Grid>

            <Stack sx={{ mt: 2 }} alignItems="center">
              <WaitButton type="submit" color="primary" variant="outlined" wait={updateState.status === 'pending'}>
                Update
              </WaitButton>
            </Stack>
          </Box>
        )}
      </Form>
    </Paper>
  );
}
