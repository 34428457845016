import { Box, Alert, AlertTitle, Grid, Typography, Paper, TextField, Stack } from '@mui/material';
import { Form } from 'components/form';
import { WaitButton, Link } from 'components';
import { useAsyncState } from 'app/hooks';
import { revokeConsent, useAppDispatch } from 'app/store';

type OptoutData = {
  optout: string;
};

const validators = {
  optout: (value: string) => {
    if (value !== 'OPTOUT') {
      return 'You must type OPTOUT in this field to confirm your intention';
    }
  },
};

export function RevokeConsentForm({ onClose }: { onClose: () => void }) {
  const dispatch = useAppDispatch();
  const [revokeState, success, failure, waiting] = useAsyncState();

  const handleSubmit = () => {
    dispatch(revokeConsent())
      .unwrap()
      .then(() => {
        success();
        onClose();
      }, failure);
    waiting();
  };

  return (
    <Paper sx={{ p: 2, my: 2 }}>
      <Typography variant="h6" color="secondary">
        Opt out
      </Typography>
      <Typography variant="subtitle1" color="textSecondary">
        You always have the right to withdraw from the PiP-Kids Autisim program and research project. If you have any
        questions or feedback, please don't hesitate to get in touch with us first (see{' '}
        <Link href="/contact">Contact Us</Link>).
      </Typography>
      <Form<OptoutData> onSubmit={handleSubmit} validators={validators} initialData={{ optout: '' }}>
        {(state, updateField) => (
          <Box mt={2}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {revokeState.status === 'failure' && (
                  <Alert severity="error" sx={{ mb: 2 }}>
                    <AlertTitle>Error</AlertTitle>
                    Oops, internal errors prevent us from revoking your account. Please try again later.
                  </Alert>
                )}

                <Alert severity="warning">
                  <AlertTitle>Warning</AlertTitle>
                  By opting out, your PiP-Kids Autisim account will be suspended immediately. You will no longer have
                  access to the program, and we won't contact you again. If you prefer to retain access to the program,
                  but receive fewer emails or cancel SMS notifications, please contact us so we can arrange this. If you
                  wish to continue, please type OPTOUT in capital letters in the box below.
                </Alert>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="optout"
                  variant="filled"
                  label="OPTOUT"
                  value={state.data.optout}
                  onChange={(e) => updateField('optout', e.target.value)}
                  error={Boolean(state.errors.optout)}
                  helperText={state.errors.optout}
                  fullWidth
                  required
                />
              </Grid>
            </Grid>

            <Stack sx={{ mt: 2 }} alignItems="center">
              <WaitButton type="submit" color="primary" variant="outlined" wait={revokeState.status === 'pending'}>
                OPT OUT
              </WaitButton>
            </Stack>
          </Box>
        )}
      </Form>
    </Paper>
  );
}
