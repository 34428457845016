import { Box, Typography, Paper, FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { useAppDispatch, updateProfile } from 'app/store';

import type { state } from 'app';

export function NotificationForm({ user }: { user: state.User }) {
  const dispatch = useAppDispatch();
  const updateSmsNotification = (value: boolean) => {
    dispatch(updateProfile({ smsNotification: value }));
  };

  return (
    <Paper sx={{ p: 2, my: 2 }}>
      <Typography variant="h6" color="secondary">
        Manage notifications
      </Typography>
      <Typography variant="subtitle1" color="textSecondary">
        You'll get email alerts when you have new module or survey available. If you've provided a mobile number, we'll
        also send you SMS notifications. Parents who have completed PiP in the past have found these helpful. If you
        prefer <i>not</i> to receive SMS, please update your preference below.
      </Typography>

      <Box mt={2}>
        <FormControl>
          <RadioGroup
            name="notification-transport"
            value={Boolean(user.smsNotification)}
            onChange={(e, value) => {
              updateSmsNotification(value === 'true');
            }}
            row>
            <FormControlLabel value={true} control={<Radio />} label="Email and SMS Notifications" />
            <FormControlLabel value={false} control={<Radio />} label="Email notifications only" />
          </RadioGroup>
        </FormControl>
      </Box>
    </Paper>
  );
}
