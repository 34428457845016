import { navigate } from 'gatsby-link';
import { Box, Breadcrumbs, Container, Typography } from '@mui/material';
import { Link } from 'components';
import { useAppSelector } from 'app/store';
import { ensureAuth } from 'app/helpers';
import { AppPage } from 'app/layout';
import { ChangePasswordForm, UserProfileForm, RevokeConsentForm, NotificationForm } from 'app/profile/settings';

function EditProfilePage() {
  const { user } = useAppSelector((state) => state.profile.data!);

  const handleClose = () => {
    navigate('/dashboard');
  };

  return (
    <AppPage title="Edit Profile">
      <Box pt={2}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link color="inherit" href="/">
            Home
          </Link>
          <Link color="inherit" href="/dashboard">
            Dashboard
          </Link>
          <Typography color="textPrimary">Settings</Typography>
        </Breadcrumbs>
      </Box>

      <Container>
        <UserProfileForm user={user} onClose={handleClose} />
        <ChangePasswordForm />
        <NotificationForm user={user} />
        <RevokeConsentForm onClose={handleClose} />
      </Container>
    </AppPage>
  );
}

export default ensureAuth(EditProfilePage);
